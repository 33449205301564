import { useEffect } from 'react';

export const useDebounce = (callback: Function, delay: number, observedValue: any) => {
    useEffect(() => {
        const handle = window.setTimeout(() => {
            callback();
        }, delay);

        return () => clearTimeout(handle);
    }, [observedValue]);
};
