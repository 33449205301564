import React from 'react';
import '../styles/css/app.css';
import {AccountButton} from "./account-button";
import UnidadeAutocompleteSelect from "./UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import MatriculaAutocompleteSelect from "./MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import Box from "@mui/material/Box";
import {useRecoilValue} from "recoil";
import {showSelectMatriculasState} from "../recoil/atoms/showSelectMatriculasState";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import ColaboradorAutocompleteSelect from './ColaboradorAutocompleteSelect/ColaboradorAutocompleteSelect';
import { showSelectColaboradorState } from 'src/recoil/atoms/showSelectColaboradorState';

export function HearderApp(props) {
  const {
    onMobileNavOpen
  } = props;
  const showSelectmatriculas = useRecoilValue(showSelectMatriculasState);
  const showSelectColaborador = useRecoilValue(showSelectColaboradorState);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <header>
      {lgUp && (
        <Stack
          alignItems="flex-end"
          direction="column"
          justifyContent="space-between"
        >
          <Box
            sx={{
              width: '200px'
            }}
          >
            <AccountButton/>
          </Box>
        </Stack>
      )}

      {!lgUp && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <IconButton
            onClick={onMobileNavOpen}
            sx={{
              width: '50px'
            }}
          >
            <SvgIcon>
              <MenuIcon/>
            </SvgIcon>
          </IconButton>

          <Box
            sx={{
              width: '200px'
            }}
          >
            <AccountButton/>
          </Box>
        </Stack>
      )}
      <Stack className="header">
        <Stack
          alignItems="flex-start"
          direction="column"
          justifyContent="space-between"
        >
          <UnidadeAutocompleteSelect/>
          {showSelectmatriculas && <MatriculaAutocompleteSelect/>}
          {showSelectColaborador && <ColaboradorAutocompleteSelect/>}
        </Stack>
      </Stack>
    </header>
  )
}

export default HearderApp;
