import {ExibeMensagem} from "../components/shared/Message";
import errorCodes from "seb-graph-api-types/errorCodes";
import {GraphQLError} from "graphql/error";
import MUTATE_REFRESH_TOKEN from "../graphql/mutations/refresh-token";
import {ApolloClient, FetchResult, Observable} from "@apollo/client";

export const STORAGE_TOKEN_KEY = 'sebAccessToken';
export const STORAGE_REFRESH_TOKEN_KEY = 'sebRefreshToken';

export function dispatchGraphError(errors = [], onlyThrowOnAuthContext = false, operation = null, forward =null) {
  if (!onlyThrowOnAuthContext) return;
  const dispatch = (errors) => {
    errors.forEach(error => {
      const {
        extensions
      } = error;
      const {
        response,
        code
      } = extensions || {};
      const {
        body,
        status,
        statusText
      } = response || {};
      const level = status >= 500 ? '2' : '1';

      switch (code) {
        case 'UNAUTHENTICATED':
          ExibeMensagem("Usuário não autenticado.", '2', true);
          // trigger msal login 
          // @ts-ignore
          window.msalInstance.loginRedirect();
          return;
        case errorCodes.SESSION_EXPIRED_ERROR:
          if (operation && operation.operationName === 'refreshToken') return;

          const observable = new Observable<FetchResult<Record<string, any>>>(
            (observer) => {
              (async () => {
                try {
                  const accessToken = await refreshToken();

                  if (!accessToken) {
                    throw new GraphQLError('Empty AccessToken');
                  }

                  // Retry the failed request
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  forward && operation && subscriber && forward(operation).subscribe(subscriber);
                } catch (err) {
                  observer.error(err);
                }
              })();
            }
          );

          return observable;
      }
      if (response) {
        if (body) {
          const {errors: bodyErrors = null} = body;
          if (bodyErrors) {
            for (let erro of bodyErrors) {
              const { message } = erro;
              if (message)
                ExibeMensagem(message, level, true);
              else
                ExibeMensagem("Erro não tratado", level, true);
            }
          } else {
            ExibeMensagem(body, level, true);
          }
        } else if (statusText) {
          ExibeMensagem(statusText, level, true);
        }
      }
    });
  }
  if ('graphQLErrors' in errors) {
    const {
      graphQLErrors,
    } = errors;
    dispatch(graphQLErrors);
  } else if (errors.length > 0) {
    dispatch(errors);
  }
}

const refreshToken = async () => {
  try {
    // @ts-ignore
    const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const refreshResolverResponse = await client.mutate<{
      refreshToken: any
    }>({
      mutation: MUTATE_REFRESH_TOKEN,
    });

    const accessToken = refreshResolverResponse.data?.refreshToken.accessToken;
    const refreshToken = refreshResolverResponse.data?.refreshToken.refreshToken;
    localStorage.setItem(STORAGE_TOKEN_KEY, accessToken || '');
    localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken || '');
    return accessToken;
  } catch (err) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  }
};