import React, {useEffect, useState} from 'react';
import * as _unidadeService from "../../service/unidade-service";
import * as _carteirinhaTemplate from "../../service/carterinha-service";
import {Autocomplete, TextField} from "@mui/material";
import {useRecoilState} from "recoil";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import {unidadesState} from "../../recoil/atoms/unidadesState";
import Box from "@mui/material/Box";
import {darken, lighten, styled} from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';
import './styles.css';
import useSelectUnidade from "../../hooks/use-select-unidade";


const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export interface Unidade {
  bairro: string,
  bancoPagamentoId: string,
  bloqueioContratacao: false
  cep: string,
  cidade: string,
  cnpj: string,
  codigoERP: string,
  complemento: string,
  dataAreaId: string,
  data_limite_desconto_adiantamento: string,
  descontoEntradaVista: number,
  diasVencimentoPagamento: string[],
  endereco: string,
  estado: string,
  grupo: string,
  id: string,
  logo: string,
  logradouro: string,
  mensagemBloqueio: string,
  nome: string,
  pais: string,
  parametroPagamentoId: string,
  razaoStatusValue: number,
  site: string,
  siteUrl: string,
  statusValue: number,
  telefone: string,
  urlFluxoAgendamento: string,
  urlLandingPage: string,
  urlVideoMatricula: string,
  possuiCarteirinha: boolean
}

function UnidadeAutocompleteSelect(
  {
    maxWidth = 600,
  }
) {
  const [unidades, setUnidades] = useRecoilState(unidadesState);
  const [unidade, setUnidade] = useRecoilState(selectedUnidadeState);
  const [loading, setLoading] = useState(true);
  const selected = unidade !== undefined ? {
    label: unidade?.nome,
    value: unidade
  } : undefined;
  const {selecioneUnidade} = useSelectUnidade();

  useEffect(() => {
    _unidadeService.GetAll()
      .then(listUnidades => {
        if (listUnidades != null) {
          setUnidades(listUnidades.hasOwnProperty('length') ? listUnidades : []);
        }

        if (unidade == null) {
          sessionStorage.setItem('@SECRETARIA/logoSistema', '');
          sessionStorage.setItem('@SECRETARIA/idUnidade', '');
          sessionStorage.setItem('@SECRETARIA/unidade', '');
        } else {
          sessionStorage.setItem('@SECRETARIA/unidade', JSON.stringify(unidade));
          sessionStorage.setItem('@SECRETARIA/logoSistema', unidade.logo);
          sessionStorage.setItem('@SECRETARIA/idUnidade', unidade.id);
          sessionStorage.setItem('@SECRETARIA/entidadeLegal', unidade.codigoERP);
          sessionStorage.setItem('@SECRETARIA/dataAreaId', unidade.dataAreaId);
          if (listUnidades != null) {
            setUnidade((listUnidades || []).find(u => u?.id === unidade?.id))
          }
        }

        setLoading(false);
      });
  }, []);
  
  useEffect(() => {
    const url = new URL(window.location.href)
    const unidadeId = url.searchParams.get("unidadeId")
    
    if (unidades && unidadeId && (!unidade || unidade?.id !== unidadeId)) {
      selecioneUnidade((unidades || []).find(u => u.id === unidadeId))
    }
  }, [unidades, unidade]);

  return (
    <React.Fragment>
      <Autocomplete
        loading={loading}
        disablePortal
        openOnFocus
        fullWidth
        options={unidades.map(u => ({
          label: u.nome,
          value: u
        })).sort((a, b) => -b.label.charAt(0).localeCompare(a.label.charAt(0)))}
        groupBy={(option) => option.label.charAt(0).toUpperCase()}
        sx={{
          m: 2,
          maxWidth: 600,
        }}
        isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
        disableClearable
        value={selected}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
            <img
              loading="lazy"
              width="50"
              src={option.value.logo}
              srcSet={option.value.logo}
              alt=""
            />
            {option.label}
          </Box>
        )}
        // @ts-ignore
        onChange={(event, newValue: { label: string, value: Unidade }) => {
          selecioneUnidade(newValue?.value);
        }}
        size={'small'}
        noOptionsText={'Nenhuma unidade encontrada'}
        loadingText={'Carregando...'}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Unidade"
            autoComplete="off"
            type="text"
            name="unidade"
            /*@ts-ignore*/
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              startAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20}/> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    </React.Fragment>
  );
}

export default UnidadeAutocompleteSelect;
