export const appConfig = {
  appId: process.env.REACT_APP_CLIENT_ID,
}

export const graphApiConfig = {
  uri: process.env.REACT_APP_API_URL
}


export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
  clarityId: process.env.REACT_APP_CLARITY_ID
};
