import { useState } from 'react'

export const useFormSteps = (steps : JSX.Element[]) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [prevStep, setPrevStep] = useState(null);

    const changeStepAndPrevStep = (i : number, prevPosition: number = null, e : React.MouseEvent = null) => {
        if (e)
            e.preventDefault()
        if (i > steps.length)
            return

        if (prevPosition === null)
            setPrevStep(null);
        else
            setPrevStep(prev => {
                const newPrevStep = prev ? [...prev, prevPosition] : [prevPosition];
                return newPrevStep;
            });
        setCurrentStep(i);
    }

    const changeStep = (i : number, e : React.MouseEvent = null) => {
        if (e)
            e.preventDefault()
        if (i > steps.length)
            return
        
        setCurrentStep(i)
    }
    const prevComponentsByPosition = prevStep && prevStep.length > 0 && prevStep.map((prev) => steps[prev])
    return {
        currentStep,
        prevStep,
        currentComponent: steps[currentStep],
        prevComponent: steps[prevStep],
        prevComponents: prevComponentsByPosition,
        changeStepAndPrevStep,
        changeStep
    }
}