import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

import './styles.css';

interface ErrorMessageProps {
  message: string;
  title?: string;
  info?: boolean;
}

const ErrorMessage = ({ message, title = null, info = false } : ErrorMessageProps) => {
    return (
      <div className="error-message-container">
        <ErrorIcon 
          className="error-icon"
          color={info ? "info" : "error"}
        />
        <h2 className={info ? "info-title" : "error-title"}>{title ? title : "Ops! Ocorreu um Erro!"}</h2>
        <Alert severity={info ? "info" : "error"}>
          <AlertTitle className="error-message">{message}</AlertTitle>
        </Alert>
      </div>
    );
  };
  
  export default ErrorMessage;