import React from 'react'
import './styles.css'
import { Box, Stack } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingPageAnimation = () => {
    return (
      <Box
        component='main'
        sx={{
          flexGrow: 1
        }}
      >
        <Stack 
          spacing={3} 
          sx={{ 
            mb: 3,
            minHeight: '20vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress
            color='primary'
            size={55}
          />
        </Stack>
      </Box>
    )
  }
  
  export default LoadingPageAnimation