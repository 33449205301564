import React from 'react';
import HorarioSemanal from './horario-semanal';
import { Container, Grid, Stack, Tooltip, Typography, Divider, Grow } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ControllerCard from '../ControllerCard';
import { LoadingPageAnimation } from '../LoadingAnimation/LoadingPageAnimation';
import { Intervals, Controllers } from "../../types/guardian-types";


interface Props {
  unitControllers: Controllers[];
  entryTime: Intervals[];
  exitTime: Intervals[];
  loadingControllers: boolean;
  loadingHorarios: boolean;
}

const ModalHorario = (props: Props) => {
  const {
    unitControllers,
    loadingControllers,
    loadingHorarios,
    entryTime,
    exitTime,
  } = props;

  if (loadingControllers || loadingHorarios) return <LoadingPageAnimation />;
  return (
    <Container
      sx={{ mt: 2 }}
    >
      <Grow
        in={!loadingHorarios}
        easing="ease-out"
        mountOnEnter
        unmountOnExit
        timeout={800}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Grid
              item
              xs={12}
              sx={{
                mb: 3
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h6" align="left">
                  Horários de Entrada
                </Typography>
                <Tooltip title="Tabela referente aos horários de entrada permitidos nas catracas.">
                  <InfoIcon
                    color='info'
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 3,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '100px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <HorarioSemanal
                  intervals={entryTime}
                />
              </Grid>
            </Grid>
            {unitControllers.filter((controller) => controller.name.toLowerCase().includes('entrada')).length > 1 && (
              <Grid
                item
                xs={12}
                sx={{
                  mb: 3
                }}
              >
                <Grow
                  in={!loadingControllers}
                  easing="ease-out"
                  mountOnEnter
                  unmountOnExit
                  timeout={800}
                >
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexWrap="wrap"
                    sx={{
                      backgroundColor: 'var(--bs-gray-200)',
                      borderRadius: '20px',
                      minHeight: '150px',
                    }}
                  >
                    {unitControllers.filter((controller) => controller.name.toLowerCase().includes('entrada')).map((controller, index) => (
                      <Stack
                        key={index}
                        sx={{
                          margin: '10px 5px'
                        }}
                      >
                        <ControllerCard
                          controller={controller}
                        />
                      </Stack>
                    ))}
                  </Grid>
                </Grow>
              </Grid>
            )}
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid
              item
              xs={12}
              sx={{
                mb: 3
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h6" align="left">
                  Horários de Saída
                </Typography>
                <Tooltip title="Tabela referente aos horários de saída permitidos nas catracas.">
                  <InfoIcon
                    color='info'
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 3
              }}
            >
              <Grow
                in={!loadingHorarios}
                easing="ease-out"
                mountOnEnter
                unmountOnExit
                timeout={800}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <HorarioSemanal
                    intervals={exitTime}
                  />
                </Grid>
              </Grow>
            </Grid>
            {unitControllers.filter((controller) => controller.name.toLowerCase().includes('saida')).length > 1 && (
              <Grid
                item
                xs={12}
                sx={{
                  mb: 3
                }}
              >
                <Grow
                  in={!loadingControllers}
                  easing="ease-out"
                  mountOnEnter
                  unmountOnExit
                  timeout={800}
                >
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexWrap="wrap"
                    sx={{
                      backgroundColor: 'var(--bs-gray-200)',
                      borderRadius: '20px',
                      minHeight: '150px',
                      width: '602px'
                    }}
                  >
                    {unitControllers.filter((controller) => controller.name.toLowerCase().includes('saida')).map((controller, index) => (
                      <Stack
                        key={index}
                        sx={{
                          margin: '10px 5px'
                        }}
                      >
                        <ControllerCard
                          controller={controller}
                        />
                      </Stack>
                    ))}
                  </Grid>
                </Grow>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grow>
    </Container>
  );
}

export default ModalHorario;