import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function ListByIdUnidade(idUnidade, matriculaId, tipoItem) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.query({
        query:  gql`
            query ListTurmaByIdUnidade($listTurmaByIdUnidadeFilter: ListTurmaByIdUnidadeFilter) {
                listTurmaByIdUnidade(listTurmaByIdUnidadeFilter: $listTurmaByIdUnidadeFilter) {
                    id
                    nome
                    anoLetivo_Name
                    anoLetivo_Id
                    curso_Name
                    curso_Id
                    segmento_Name
                    unidade_Name
                    unidade_Id
                    periodo_Value
                    nomeLandingPage
                    codigoERP
                    codigoServicoERP
                    valorBase
                    maxParcelas
                    permiteBolsa
                    permiteBolsaEntradaNova
                    considerarProporcionalidade
                    dataCalculoProporcionalidade
                    mesInicioProporcionalidade
                    mesdePulo
                    contratoHTML_Id
                    contratoHTML
                    dataInicio
                    dataTermino
                    dataInicioVigencia
                    possuiEntrada
                    status
                    razaoStatus
                    nomeNoSite
                    subtitulo
                    imagem
                    descricao
                    descricaoHorario
                    descricaoServico
                    detalhamentoServico
                    bullets
                    codigo
                    nomeApresentacao
                    tipoItem {
                        valor
                        descricao
                    }
                    fatorDebitoAutomatico
                    fatorDebitoAutomaticoRecorrente
                    fatorBoleto
                    dataInicioAula
                    possuiReservaVaga
                    valorReservaVaga
                    formaPagamentoBoletoReserva
                    formaPagamentoCartaoReserva
                    numeroParcelasReserva
                    modeloTermoReservaId
                }
            }
        `,
        variables: {
            listTurmaByIdUnidadeFilter: {
                idUnidade,
                matriculaId,
                tipoItem
            },
        }
    }).then(data => {
        const {
            data: {
                listTurmaByIdUnidade
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return listTurmaByIdUnidade;
    }).catch(console.error)
}


export async function ListOnlyByIdUnidade(idUnidade, anoLetivo, nomeServico, segmento, filterMaterialTaxa = true) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.query({
        query:  gql`
            query ListOnlyByIdUnidade($idUnidade: String,$listServicosByUnidadeFilter: ListServicosByUnidadeFilter) {
                listOnlyByIdUnidade(idUnidade: $idUnidade, listServicosByUnidadeFilter: $listServicosByUnidadeFilter) {
                    id
                    nome
                    anoLetivo_Name
                    anoLetivo_Id
                    curso_Name
                    curso_Id
                    segmento_Name
                    segmento_Id
                    unidade_Name
                    unidade_Id
                    periodo_Value
                    nomeLandingPage
                    codigoERP
                    codigoServicoERP
                    valorBase
                    maxParcelas
                    permiteBolsa
                    permiteBolsaEntradaNova
                    considerarProporcionalidade
                    dataCalculoProporcionalidade
                    mesInicioProporcionalidade
                    mesdePulo
                    contratoHTML_Id
                    contratoHTML
                    dataInicio
                    dataTermino
                    dataInicioVigencia
                    possuiEntrada
                    status
                    razaoStatus
                    nomeNoSite
                    subtitulo
                    imagem
                    descricao
                    descricaoHorario
                    descricaoServico
                    detalhamentoServico
                    bullets
                    codigo
                    nomeApresentacao
                    tipoItem {
                        valor
                        descricao
                    }
                    fatorDebitoAutomatico
                    fatorDebitoAutomaticoRecorrente
                    fatorBoleto
                    dataInicioAula
                    possuiReservaVaga
                    valorReservaVaga
                    formaPagamentoBoletoReserva
                    formaPagamentoCartaoReserva
                    numeroParcelasReserva
                    modeloTermoReservaId
                }
            }
        `,
        variables: {
            idUnidade,
            listServicosByUnidadeFilter: {
                anoLetivo,
                nomeServico,
                segmento,
                filterMaterialTaxa
            },
        }
    }).then(data => {
        const {
            data: {
                listOnlyByIdUnidade
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return listOnlyByIdUnidade;
    }).catch(console.error)
}