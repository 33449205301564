export const motivosVisitas = {
    "Lead - Conhecer a Escola": "esporadico",
    "Funcionário Terceiro": "recorrente",
    "Terceiro - Manutenção": "esporadico",
    "Funcionário de outra Unidade": "esporadico",
    "Buscar Aluno - van, avô etc": "recorrente",
    "Alunos que fazem atividades extras": "recorrente",
    "Outro Motivo": "recorrente",
};

export default motivosVisitas;