import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetControllersGroupInUnit(idUnidade: String) {
    // @ts-ignore
  const client = window.apolloClient ;
  const { 
    data: { getControllersGroupInUnit }, errors 
  } = await client.query({
    query: gql`
        query GetControllersGroupInUnit($idUnidade: ID!) {
          getControllersGroupInUnit(idUnidade: $idUnidade) {
            succeeded
            errors
            data {
              id
              uId
              name
              unitCrmId
            }
          }
        }
    `,
    variables: {
      idUnidade
    }
  })

  // @ts-ignore
  dispatchGraphError(errors);

  return getControllersGroupInUnit;
}

export async function GetControllerGroup(controllerGroupId: String) {
  // @ts-ignore
const client = window.apolloClient ;
const { 
  data: { getControllerGroup }, errors 
} = await client.query({
  query: gql`
      query GetControllerGroup($controllerGroupId: ID!) {
        getControllerGroup(controllerGroupId: $controllerGroupId) {
          succeeded
          errors
          data {
            id
            uId
            name
            unitCrmId
            controllers {
              id
              uId
              name
              model
              env
              fromWay
              privateIpAdress
              publicIpAdress
            }
          }
        }
      }
  `,
  variables: {
    controllerGroupId
  }
})

// @ts-ignore
dispatchGraphError(errors);

return getControllerGroup;
}