import React, { Fragment, useEffect, useMemo, useState } from "react";
import * as Yup from 'yup';
import {
  Button,
  Grid,
  TextField,
  Divider,
  Grow,
  CircularProgress,
  StepLabel,
  Step,
  Stepper,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Box,
  Skeleton
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as creatingContactData from '../../styles/jsonAnimations/review-animation.json'
import * as analyzingVisit from '../../styles/jsonAnimations/letter-animation.json'
import * as clockAnimation from '../../styles/jsonAnimations/clock-animation.json'
import * as successAnimation from '../../styles/jsonAnimations/success-animation.json'
import * as errorAnimation from '../../styles/jsonAnimations/error-animation.json'
import SaveIcon from '@mui/icons-material/Save';
import { pt } from 'yup-locale-pt';
import withUnidade from "../../hocs/withUnidade";
import csatList from "../../utils/csatList";
import * as _contatoService from "../../service/contatos-service";
import * as _aiService from "../../service/ai-service";
import * as _guardianService from "../../service/guardian-service";
import { Unidade } from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import CustomTable from "src/components/CustomTable";
import { AuthContext } from "src/context/AuthContext";
import { Add, Edit, KeyboardBackspace, Search } from "@mui/icons-material";
import { useFormik } from 'formik';
import { useFormSteps } from "../../hooks/use-form-step";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Contato, ImageQualityReport } from "seb-graph-api-types/generated";
import FacialFoto from "src/components/ModalUploadFacialImage/FacialFoto";
import { ModalUploadFacialImage } from "src/components/ModalUploadFacialImage/ModalUploadFacialImage";
import { useCan } from "src/hooks/use-can";
import { useDialog } from "src/hooks/use-dialog";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import HorarioComponent from "src/components/HorarioComponent";
import { Intervals } from "src/types/guardian-types";
import { motivosVisitas } from "src/utils/motivosList";
import { useDebounce } from "src/hooks/use-debounce";
import { ExibeMensagem } from "src/components/shared/Message";
import { Etapa, ProgressoRequisicoesComAnimacao } from "src/components/ProgressRequisition";
import permissions from "seb-graph-api-types/permissions";
import { error } from "src/theme/colors";
import dayjs from "dayjs";


Yup.setLocale(pt);

interface FormData {
  id?: string
  nome?: string
  email?: string
  cpf?: string
  telefoneFixo?: string
  celular?: string
  dataNascimento?: string
  fotoPrincipal64?: null | string
  fotoPrincipal?: any
  seb_motivo?: string
  seb_outro_motivo?: null | string
  seb_data_inicio?: string
  seb_data_fim?: string
  startTime?: null | Date
  endTime?: null | Date
}

interface Props {
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

const jsDays = {
  isSunday: false,
  isMonday: false,
  isTuesday: false,
  isWednesday: false,
  isThursday: false,
  isFriday: false,
  isSaturday: false,
}

const formatarHorario = (rules) => {
  const diasSemana = {
    isMonday: 'Seg',
    isTuesday: 'Ter',
    isWednesday: 'Qua',
    isThursday: 'Qui',
    isFriday: 'Sex',
    isSaturday: 'Sáb',
    isSunday: 'Dom'
  };

  const obterDiasAtivos = (rule) => {
    const diasAtivos = [];
    for (const [key, dia] of Object.entries(diasSemana)) {
      if (rule[key]) {
        diasAtivos.push(dia);
      }
    }
    return diasAtivos;
  }

  const agruparDias = (dias) => {
    const grupos = [];
    let grupoAtual = [dias[0]];

    for (let i = 1; i < dias.length; i++) {
      if (diasSemana[dias[i - 1]] === diasSemana[dias[i] - 1]) {
        grupoAtual.push(dias[i]);
      } else {
        grupos.push(grupoAtual);
        grupoAtual = [dias[i]];
      }
    }

    grupos.push(grupoAtual);
    return grupos;
  }
  return rules.map(rule => {
    let horario = '';
    rule.intervals && rule.intervals.length > 0 && rule.intervals.map(interval => {
      const diasAtivos = obterDiasAtivos(interval);
      const grupos = agruparDias(diasAtivos);
      const diasTexto = grupos.map(grupo => {
        if (grupo.length === 1) {
          return grupo[0];
        } else {
          return grupo.join(', ');
        }
      }).join(', ');
      horario = `${diasTexto} das ${interval.startTime.substring(0, 5)} às ${interval.endTime.substring(0, 5)}`;
      return interval;
    })
    return horario;
  }).join('; ');
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialContatoState = {
  id: '',
  nome: '',
  cpf: '',
  celular: '',
  fotoPrincipal: null,
  fotoSecundaria: null,
}

const initialValues: FormData = {
  id: null,
  nome: '',
  email: '',
  cpf: '',
  telefoneFixo: '',
  celular: '',
  dataNascimento: '',
  fotoPrincipal64: null,
  fotoPrincipal: null,
  seb_motivo: null,
  seb_data_inicio: new Date().toString(),
  seb_data_fim: new Date().toString(),
  seb_outro_motivo: null,
  startTime: null,
  endTime: null,
};

type ControllerGroup = {
  id: string
  uId: string
  name: string
}

interface Rules {
  id: string;
  name: string;
  uId: string;
  intervals: Intervals[];
}

interface InfoGuardian {
  id: string;
  visitCrmId: string;
  rules: Rules[];
}
interface VisitasTratadas {
  rules: Rules[];
  infoGuardianVisita: InfoGuardian;
  seb_visitaid: string;
  seb_name: string;
  seb_motivo: string | null;
  seb_unidade: string;
  seb_unidadename: string;
  seb_data_inicio: string;
  seb_data_fim: string;
  seb_horario: string;
  intervalStatus: string;
  contactid: string;
  contactname: string;
  contactcpf: string;
}

const ReconhecimentoFacial = (props: Props) => {
  const {
    Unidade,
    handleShowCsat
  } = props;

  const [defaultVisitasTratadas, setDefaultVisitasTratadas] = useState<VisitasTratadas[]>([]);
  const [visitas, setVisitas] = useState([]);
  const dialog = useDialog();
  const [loadingVisitas, setLoadingVisitas] = useState<boolean>(false);
  const [loadingControladores, setLoadingControladores] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [isEsporadico, setIsEsporadico] = useState<boolean>(false);
  const [loadingHorarios, setLoadingHorarios] = useState<boolean>(true);
  const [iaGenerativeText, setIaGenerativeText] = useState<string>('');
  useDebounce(() => generateAiHorario(), 2000, iaGenerativeText);
  const [controladores, setControladores] = useState<ControllerGroup[]>([]);
  const steps = useMemo(() => ['Lista das Visitas', 'Gerenciamento da Visita'], []);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [nomeContato, setNomeContato] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [contactAlreadyExist, setContactAlreadyExist] = useState<boolean>(false);
  const [cpfContato, setCpfContato] = useState<string>('');
  const [isLoadingContatoByCpf, setIsLoadingContatoByCpf] = useState<boolean>(false);
  const [loadingContato, setIsLoadingContato] = useState<boolean>(false);
  const [selectedContato, setSelectedContato] = useState<Contato>(initialContatoState);
  const [selectedVisita, setSelectedVisita] = useState(null);
  const [entryTime, setEntryTime] = useState<Intervals[]>([]);
  const [exitTime, setExitTime] = useState<Intervals[]>([]);
  const [errorText, setErrorText] = useState<string>('');
  const { userCan } = useCan();
  const canCreate = false;
  const canEdit = userCan(permissions.cadastro.reconhecimentoFacial.edit.userBasicInfo);
  const canEditFoto = userCan(permissions.cadastro.reconhecimentoFacial.edit.userFoto);
  const [etapas, setEtapas] = useState<Etapa[]>([]);

  const readOnlyFields = !(canEdit && selectedContato.id !== null && !canCreate) && !(canCreate && selectedContato.id === null);
  const readOnlyPhoto = !(canCreate && selectedContato.id === null) && !(canEditFoto && selectedContato.id !== null);
  const hasIdInContato = 'id' in selectedContato && selectedContato?.id && selectedContato?.id?.length > 0 ? true : false;
  const reports = useMemo<ImageQualityReport>(
    () => selectedContato?.fotoPrincipal?.props?.metadata?.analysisDecoded?.imageQualityReport ?? {} as ImageQualityReport,
    [selectedContato]
  );
  const [showWebcam, setShowWebcam] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href)
    const openUploadModal = url.searchParams.get("openUploadModal")
    const contatoId = url.searchParams.get("contatoId")

    if (openUploadModal && dialog && selectedContato && selectedContato?.id === contatoId && !showWebcam) {
      setShowWebcam(true);
      dialog.handleOpen();
    }
  }, [dialog, showWebcam, selectedContato]);


  const isIntervalActive = (startTime: string, endTime: string) => {
    const now = new Date();
    const start = new Date(`${now.toDateString()} ${startTime}`);
    const end = new Date(`${now.toDateString()} ${endTime}`);
    if (start > now) {
      return 'A iniciar';
    } else if (end < now) {
      return 'Inativo';
    } else {
      return 'Ativo';
    }
  }

  const handleSave = (intervals: Intervals[], isEntry: boolean) => {
    if (isEntry)
      setEntryTime(intervals);
    else
      setExitTime(intervals);
  }

  useEffect(() => {
    setErrorText(null);
    getVisitas();
    getControlladores();
  }, [Unidade.id]);

  useEffect(() => {
    if (etapas.length > 0) {
      setSaving(true);
    }
  }, [etapas]);

  const getControlladores = async () => {
    setLoadingControladores(true);
    try {
      const resp = await _guardianService.crmUnitControllerGroups({ unitCrmId: Unidade?.id });
      const { data: controladores } = resp || {};
      const entradaSaidaControladores = controladores.filter(
        (group) =>
          group.name.toLowerCase().includes("saída") ||
          group.name.toLowerCase().includes("saida")
      )
      if (!entradaSaidaControladores || entradaSaidaControladores.length === 0)
        setErrorText('Não foi encontrado controladores de entrada e saída para essa unidade.');
      controladores && setControladores(controladores);
    } finally {
      setLoadingControladores(false);
    }
  }

  const getContatoByCPF = async (cpf: string) => {
    setIsLoadingContatoByCpf(true);
    setContactAlreadyExist(false);
    try {
      const contato = await _contatoService.getContatoByCPF(cpf);
      if (contato && contato.id && contato.cpf && contato.cpf !== '') {
        ExibeMensagem('Já existe um contato com esse CPF! Você pode alterar os dados do Contato no CRM.', 'warning', true);
        setContactAlreadyExist(true);
        setSelectedContato(contato);
      } else {
        setSelectedContato({ ...initialContatoState, cpf });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingContatoByCpf(false);
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Data inválida:", dateString);
      return '';
    }
    const typeDate = date?.toISOString()?.split('T')?.[0] || '';
    return typeDate;
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const [year, month, day] = value.split('-');
    const fullDate = new Date(year, month - 1, day);
    formik.setFieldValue(name, fullDate);
  };

  const handleTimeChange = (name,value) => {
    formik.setFieldValue(name, value);
  }

  const showBrDate = (date) => {
    const brDate = new Date(date);
    return brDate.toLocaleDateString('pt-BR');
  };

  const getVisitas = async () => {
    setLoadingVisitas(true);
    try {
      const visitas = await _contatoService.GetVisitasPorUnidade(Unidade.id);
      const rulesByVisit = await Promise.all(visitas.map(async visita => {
        return await _contatoService.GetVisitasPorCRMIdGuardian(visita.seb_visitaid);
      }));
      let visitantes = [];
      visitas.map((visita) => {

        let filteredRules = rulesByVisit.find(groupedRules => groupedRules.find(rule => rule.visitCrmId === visita.seb_visitaid)) || [];

        visita.rules = filteredRules && filteredRules.length > 0 ? filteredRules[0].rules : [];
        visita.contatos.map((contato) => {
          visitantes.push({
            rules: visita.rules,
            infoGuardianVisita: rulesByVisit.find(groupedRules => groupedRules.find(rule => rule.visitCrmId === visita.seb_visitaid)),
            seb_visitaid: visita.seb_visitaid,
            seb_name: visita.seb_name,
            seb_motivo: visita.seb_motivo,
            seb_unidade: visita.seb_unidade,
            seb_unidadename: visita.seb_unidadename,
            seb_data_inicio: visita.seb_data_inicio,
            seb_data_fim: visita.seb_data_fim,
            contactid: contato.contactid,
            contactname: contato.contactname,
            contactcpf: contato.contactcpf,
            seb_horario: formatarHorario(visita.rules),
            intervalStatus: isIntervalActive(visita.seb_data_inicio, visita.seb_data_fim)
          });
          return contato;
        });
        return visita;
      });
      setDefaultVisitasTratadas(visitantes);
      setVisitas(visitantes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVisitas(false);
    }
  }
  const handleSelectVisita = async (contato) => {
    setIsLoadingContato(true);
    changeStep(currentStep + 1);
    setSelectedContato(initialContatoState);
    setSelectedVisita(null);
    setIaGenerativeText('');
    setEntryTime([]);
    setExitTime([]);
    try {
      let selectedVisita = defaultVisitasTratadas.find(visita => visita.seb_visitaid === contato.seb_visitaid);
      setSelectedVisita(selectedVisita);
      const visitante = await _contatoService.FindOne(contato.contactid);
      setSelectedContato(visitante);
      const entryRule = selectedVisita.rules.find(rule => rule.name.toLowerCase().includes('entrada'));
      const exitRule = selectedVisita.rules.find(rule => rule.name.toLowerCase().includes('saida') || rule.name.toLowerCase().includes('saída'));
      const entryTime = entryRule?.intervals || [];
      const exitTime = exitRule?.intervals || [];
      if (entryTime || exitTime) {
        setEntryTime(entryTime);
        setExitTime(exitTime);
        setLoadingHorarios(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingContato(false);
    }
  };

  const handleNovaVisita = async () => {
    setSelectedContato(initialContatoState);
    setSelectedVisita(null);
    setIsCreating(true);
    setSelectedContato(initialContatoState);
    setIaGenerativeText('');
    setEntryTime([]);
    setExitTime([]);
    changeStep(currentStep + 1);
  };

  const generateAiHorario = async () => {
    if (!iaGenerativeText || iaGenerativeText === '')
      return;
    try {
      setLoadingHorarios(true);
      const request = {
        use_cache: true,
        message: iaGenerativeText,
        json_response: true
      };
      const { entry, exit } = await _aiService.generateAiInterval(request);
      setEntryTime(entry);
      setExitTime(exit);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHorarios(false);
    }
  }

  const onChangeCpf = (e) => {
    const { value } = e.target;
    let cpf = value.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return cpf;
  }

  const validationSchema = Yup.object<FormData>({
    nome: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().email().nullable(),
    rg: Yup.string().nullable(),
    cpf: Yup.string().nullable(),
    telefoneFixo: Yup.string().nullable(),
    celular: Yup.string().nullable(),
    dataNascimento: Yup.string().nullable(),
    fotoPrincipal64: Yup.string().nullable(),
    seb_motivo: Yup.string().required('Motivo é obrigatório'),
    seb_outro_motivo: Yup.string().nullable(),
    startTime: Yup.string().nullable().test('time', 'Horário de início é obrigatório', (value) => {
      if (!isEsporadico) return true;
      return value !== '00:00:00';
    }),
    endTime: Yup.string().nullable().test('time', 'Horário de fim é obrigatório', (value) => {
      if (!isEsporadico) return true;
      return value !== '00:00:00';
    }).test('time', 'Horário de fim deve ser maior que o horário de início', (value) => {
      if (!isEsporadico) return true;
      const startTime = formik.values.startTime;
      if (!startTime) return false;
      const endTime = value;
      if (!endTime) return false;
      const startTimeInDate = dayjs(startTime).toDate();
      const endTimeInDate = dayjs(endTime).toDate();
      return endTimeInDate > startTimeInDate;
    }),
    seb_data_inicio: Yup.string().nullable().test('date', 'Data de início, deve ser maior ou igual a data atual.', (value) => {
      if (!value) return true;
      const today = new Date();
      const date = new Date(value);
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      if (isCreating)
        return date instanceof Date && !isNaN(date.getTime()) && date >= today;
      else
        return date instanceof Date && !isNaN(date.getTime());
    }),
    seb_data_fim: Yup.string().nullable().test('date', 'Data inválida! A data deve ser maior ou igual a data de inicio', (value) => {
      if (!value) return true;
      const startDate = new Date(formik.values.seb_data_inicio);
      startDate.setHours(0, 0, 0, 0);
      const date = new Date(value);
      return date instanceof Date && !isNaN(date.getTime()) && date >= startDate;
    }).test('date', 'Data de fim deve ter uma duração de no máximo 6 meses', (value) => {
      if (!isEsporadico) {
        const startDate = new Date(formik.values.seb_data_inicio);
        const maximumDate = new Date(startDate.setMonth(startDate.getMonth() + 6));
        const date = new Date(value);
        if (date.getMonth() > maximumDate.getMonth() && date.getFullYear() >= maximumDate.getFullYear()) {
          return false;
        }
        return date <= maximumDate;
      };
      return true
    }),
  });

  const formik = useFormik({
    initialValues: { ...initialValues, ...selectedContato, ...selectedVisita },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      const criarContato = async (): Promise<Contato> => {
        const contatoRequest = {
          nome: values.nome,
          email: values.email,
          cpf: values.cpf,
          celular: values.celular,
          fotoPrincipal: values.fotoPrincipal64,
        };
        return _contatoService.createContato(contatoRequest);
      }
      const criarVisita = async (resp: Contato) => {
        const visitaContato = {
          seb_name: "visita - " + values.nome,
          seb_motivo: values.seb_motivo === 'Outro Motivo' ? values.seb_outro_motivo : values.seb_motivo,
          seb_unidade: Unidade.id,
          seb_data_inicio: new Date(values.seb_data_inicio).toISOString(),
          seb_data_fim: isEsporadico ? new Date(values.seb_data_inicio).toISOString() : new Date(values.seb_data_fim).toISOString(),
          contatos: [
            {
              contactid: resp.id,
            },
          ],
        };

        const createVisita = await _contatoService.createVisitaContato(visitaContato);
        const { data } = await _guardianService.createVisitaGuardian(createVisita.seb_visitaid);
        return data;
      };

      const updateVisita = async () => {
        const visitaContatoRequest = {
          seb_visitaid: selectedVisita?.seb_visitaid || null,
          seb_name: "visita - " + values.nome,
          seb_motivo: values.seb_motivo === 'Outro Motivo' ? values.seb_outro_motivo : values.seb_motivo,
          seb_unidade: Unidade.id,
          seb_data_inicio: new Date(values.seb_data_inicio).toISOString(),
          seb_data_fim: new Date(values.seb_data_fim).toISOString(),
          contatos: [
            {
              contactid: selectedContato.id
            }
          ]
        };
        return _contatoService.createVisitaContato(visitaContatoRequest);
      }
      const criarHorario = async (createVisitaGuardian: any) => {
        const selectedDay = new Date(values.seb_data_inicio).getDay();
        const days = Object.keys(jsDays).reduce((acc, key, index) => {
          acc[key] = index === selectedDay + 1;
          return acc;
        }, {});

        const startTimeInHour = new Date(values.startTime)?.toTimeString()?.split(' ')[0] || values.startTime;
        const endTimeInHour = new Date(values.endTime)?.toTimeString()?.split(' ')[0] || values.endTime;

        const entryTimeObject = isEsporadico
          ? [
            {
              id: 1,
              startTime: startTimeInHour,
              endTime: endTimeInHour,
              ...days,
            },
          ]
          : [...entryTime];
        const exitTimeObject = isEsporadico ? entryTimeObject : [...exitTime];

        const entryRuleObject = {
          name: `Entrada - ${values.nome}`,
          unitCrmId: Unidade.id,
          serviceCrmId: "",
          intervals: entryTimeObject,
          controllerGroupId:
            controladores
              .filter((group) => group.name.toLowerCase().includes("entrada"))
              .map((group) => parseInt(group.id)) || [],
          visitId: createVisitaGuardian?.id ? [parseInt(createVisitaGuardian?.id)] : [],
        };

        const exitRuleObject = {
          name: `Saída - ${values.nome}`,
          unitCrmId: Unidade.id,
          serviceCrmId: "",
          intervals: exitTimeObject,
          controllerGroupId:
            controladores
              .filter(
                (group) =>
                  group.name.toLowerCase().includes("saída") ||
                  group.name.toLowerCase().includes("saida")
              )
              .map((group) => parseInt(group.id)) || [],
          visitId: createVisitaGuardian?.id ? [parseInt(createVisitaGuardian?.id)] : [],
        };

        if (entryRuleObject.controllerGroupId && entryRuleObject.controllerGroupId.length)
          await _guardianService.createRuleGuardian(entryRuleObject);
        if (exitRuleObject.controllerGroupId && exitRuleObject.controllerGroupId.length)
          await _guardianService.createRuleGuardian(exitRuleObject);
        return;
      };

      const updateHorario = async (createVisitaGuardian: any) => {
        const entryUid = selectedVisita?.rules?.find(rule => rule.name.toLowerCase().includes('entrada'))?.uId || null;
        const exitUid = selectedVisita?.rules?.find(rule => rule.name.toLowerCase().includes('saida') || rule.name.toLowerCase().includes('saída'))?.uId || null;
        
        const selectedDay = new Date(values.seb_data_inicio).getDay();
        const days = Object.keys(jsDays).reduce((acc, key, index) => {
          acc[key] = index === selectedDay + 1;
          return acc;
        }, {});
        const controladoresEntrada = controladores
          .filter(group => group.name.toLowerCase().includes('entrada'))
          .map(group => parseInt(group?.id)) || [];
        const controladoresSaida = controladores
          .filter(group => group.name.toLowerCase().includes('saida') || group.name.toLowerCase().includes('saída'))
          .map(group => parseInt(group.id)) || [];

          
        const startTimeInHour = new Date(values.startTime)?.toTimeString()?.split(' ')[0] || values.startTime;
        const endTimeInHour = new Date(values.endTime)?.toTimeString()?.split(' ')[0] || values.endTime;

        const entryTimeObject = isEsporadico
          ? [
            {
              id: 1,
              startTime: startTimeInHour,
              endTime: endTimeInHour,
              ...days,
            },
          ]
          : [...entryTime];
        const exitTimeObject = isEsporadico ? entryTimeObject : [...exitTime];

        const entryRuleObject = {
          uId: entryUid,
          name: `Entrada - ${values.nome}`,
          unitCrmId: Unidade.id,
          serviceCrmId: "",
          intervals: entryTimeObject,
          controllerGroupId: controladoresEntrada,
          visitId: createVisitaGuardian?.id ? [parseInt(createVisitaGuardian?.id)] : []
        }
        const exitRuleObject = {
          uId: exitUid,
          name: `Saída - ${values.nome}`,
          unitCrmId: Unidade.id,
          serviceCrmId: "",
          intervals: exitTimeObject,
          controllerGroupId: controladoresSaida,
          visitId: createVisitaGuardian?.id ? [parseInt(createVisitaGuardian?.id)] : []
        }
        if (entryRuleObject.controllerGroupId && entryRuleObject.controllerGroupId.length) {
          if (entryUid)
            await _guardianService.updateRuleGuardian(entryRuleObject);
          else {
            delete entryRuleObject.uId;
            await _guardianService.createRuleGuardian(entryRuleObject);
          }
        }
        if (exitRuleObject.controllerGroupId || exitRuleObject.controllerGroupId.length) {
          if (exitUid)
            await _guardianService.updateRuleGuardian(exitRuleObject);
          else {
            delete exitRuleObject.uId;
            await _guardianService.createRuleGuardian(exitRuleObject);
          }
        }
      }
      try {
        if (values.seb_motivo === 'Outro Motivo' && (!values.seb_outro_motivo || values.seb_outro_motivo === '')) {
          helpers.setErrors({ seb_outro_motivo: 'Campo obrigatório' });
          return;
        }
        if (!isEsporadico && ((!entryTime || !exitTime) || (entryTime.length === 0 || exitTime.length === 0))) {
          ExibeMensagem('É necessário adicionar o horário de entrada e saída', 'warning', true);
          return;
        }
        if (!isEsporadico && !values.seb_data_fim) {
          helpers.setErrors({ seb_data_fim: 'Campo obrigatório' });
          ExibeMensagem('É necessário adicionar a horário do fim da visita', 'warning', true);
          return;
        }
        if (isEsporadico && !values.startTime) {
          helpers.setErrors({ startTime: 'Campo obrigatório' });
          ExibeMensagem('É necessário adicionar o horário do início da visita', 'warning', true);
          return;
        }
        if (isEsporadico && !values.endTime) {
          helpers.setErrors({ endTime: 'Campo obrigatório' });
          ExibeMensagem('É necessário adicionar o horário do fim da visita', 'warning', true);
          return;
        }
        if (isEsporadico && !values.seb_data_inicio) {
          helpers.setErrors({ seb_data_inicio: 'Campo obrigatório' });
          ExibeMensagem('É necessário adicionar o horário do início da visita', 'warning', true);
          return;
        }
        if (!hasIdInContato) {
          setEtapas([
            {
              requisicao: async () => criarContato(),
              titulo: "Criação do Contato",
              animacao: creatingContactData,
              contextText: "Estamos criando o Contato",
            },
            {
              requisicao: async (contato) => criarVisita(contato),
              titulo: "Criação da Visita",
              animacao: analyzingVisit,
              contextText: "Estamos criando a Visita",
            },
            {
              requisicao: async (visitaGuardian) => criarHorario(visitaGuardian),
              titulo: "Criação das Regras",
              animacao: clockAnimation,
              contextText: "Estamos criando as Regras De Horário",
            },
          ]);
        } else {
          setEtapas([
            {
              requisicao: async () => updateVisita(),
              titulo: "Atualizando a Visita",
              animacao: analyzingVisit,
              contextText: "Estamos Criando/Atualizando a Visita",
            },
            {
              requisicao: async (visitaGuardian) => updateHorario(visitaGuardian),
              titulo: "Criação das Regras",
              animacao: clockAnimation,
              contextText: "Estamos Criado/Atualizando as Regras de Horário",
            },
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const rootInputStyles = {
    '&:focus-within fieldset, &:focus-visible fieldset': {
      boxShadow: 'none !important',
    },
  };

  const filtrarUsuarios = async (nomeContato: string, cpfContato: string) => {
    if (!nomeContato && !cpfContato) {
      setVisitas(defaultVisitasTratadas);
      return;
    } else if (nomeContato || cpfContato) {
      const filteredVisitas = defaultVisitasTratadas.filter(visita => {
        if (nomeContato && cpfContato) {
          return visita.contactname.toLowerCase().includes(nomeContato.toLowerCase()) && visita.contactcpf.includes(cpfContato);
        } else if (nomeContato) {
          return visita.contactname.toLowerCase().includes(nomeContato.toLowerCase());
        } else if (cpfContato) {
          return visita.contactcpf.includes(cpfContato);
        } else {
          return false;
        }
      });
      setVisitas(filteredVisitas);
    }
  }



  const visitanteComponents = [
    <>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
          mb: 2
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            size="medium"
            value={nomeContato}
            onChange={(e) => {
              setNomeContato(e.target.value);
              filtrarUsuarios(e.target.value, cpfContato);
            }}
            InputProps={{
              sx: {
                ...rootInputStyles,
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            fullWidth
            label="CPF"
            variant="outlined"
            size="medium"
            value={cpfContato}
            onChange={(e) => {
              const cpf = onChangeCpf(e);
              setCpfContato(cpf);
              filtrarUsuarios(nomeContato, cpf);
            }}
            InputProps={{
              sx: {
                ...rootInputStyles,
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Add />}
            disabled={loadingVisitas || loadingControladores}
            onClick={handleNovaVisita}
          >
            Criar Visita
          </Button>
        </Grid>
      </Grid>
      {defaultVisitasTratadas.length > 0 && visitas.length === 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          sx={{
            minHeight: '400px'
          }}
        >
          <Grid
            item
            xs={12}
          >
            <ErrorMessage
              title="Nenhum resultado encontrado"
              message="Não encontramos nenhuma visita com esses dados"
              info
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Stack
              flexDirection="row"
              spacing={2}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Search />}
                onClick={() => setVisitas(defaultVisitasTratadas)}
              >
                Mostrar todas as Visitas
              </Button>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        defaultVisitasTratadas.length === 0 && visitas.length === 0 && !loadingVisitas && !loadingControladores ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            sx={{
              minHeight: '400px'
            }}
          >
            <Grid
              item
              xs={12}
            >
              <ErrorMessage
                title="Nenhuma visita para essa Unidade"
                message="Não encontramos nenhuma visita"
                info
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
            >
              <Stack
                flexDirection="row"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Add />}
                  onClick={handleNovaVisita}
                >
                  Criar Visita
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <AuthContext.Consumer>
            {({ appContext }) => {
              const { tables } = appContext || {}
              const visitaColumns =
                tables['listvisitas-table']?.columns || []
              return (
                <CustomTable
                  columns={visitaColumns}
                  data={visitas && visitas.length > 0
                    ? visitas.map((visita) => ({
                      ...visita,
                      seb_data_inicio: showBrDate(visita.seb_data_inicio),
                      seb_data_fim: showBrDate(visita.seb_data_fim)
                    }))
                    : []
                  }
                  selected={[]}
                  configs={tableConfigs}
                  isLoading={loadingVisitas || loadingControladores}
                  staticAction={true}
                />
              )
            }}
          </AuthContext.Consumer>
        ))}
    </>,
    <Stack
      spacing={4}
      useFlexGap
      flexWrap="wrap"
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6">
          Gerenciamento
        </Typography>
        <Grid
          container
          spacing={4}
          width="100%"
          justifyContent="center"
          mt={2}
          ml="0 !important"
        >
          <Grid
            item
            md={6}
            width="100%"
            justifyContent="center"
          >
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              {...{ timeout: 1000 }}
            >
              <Grid
                item
                md={6}
              >
                <FacialFoto
                  title="Foto 1"
                  url={formik?.values?.fotoPrincipal64 || formik?.values?.fotoPrincipal?.publicURL}
                  finalScore={!formik.dirty ? reports?.finalScore : undefined}
                  disabled={readOnlyPhoto || !selectedContato}
                  width={200}
                  height={300}
                  loading={!selectedContato || loadingContato}
                  onClick={dialog.handleOpen}
                  placeholder="Anexar Foto"
                  icon={null}
                  blobProps={!formik.dirty ? selectedContato?.fotoPrincipal?.props : undefined}
                />

                <ModalUploadFacialImage
                  title="Foto 1"
                  contatoId={selectedContato?.id}
                  url={formik?.values?.fotoPrincipal64 || formik?.values?.fotoPrincipal?.publicURL}
                  finalScore={reports?.finalScore}
                  blobProps={selectedContato?.fotoPrincipal?.props}
                  loading={!selectedContato || loadingContato}
                  onClose={dialog.handleClose}
                  open={dialog.open}
                  showStats={!formik.dirty}
                  showWebcam={showWebcam}
                  onUpload={(_, base64) => {
                    formik.setFieldValue('fotoPrincipal64', base64)
                  }}
                />
              </Grid>
            </Grow>
          </Grid>

          <Grow
            in={!loadingContato}
            easing="ease-out"
            mountOnEnter
            unmountOnExit
            timeout={800}
          >
            <Grid
              item
              md={6}
              width="100%"
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 4,
                  mt: 2
                }}
              >
                <Typography variant="h6">
                  Informações do Contato
                </Typography>
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.cpf && formik.errors.cpf)}
                  fullWidth
                  helperText={String((formik.touched.cpf && formik.errors.cpf) ?? "")}
                  label="CPF"
                  name="cpf"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    e.target.value = onChangeCpf(e);
                    if (e.target.value.length === 14)
                      getContatoByCPF(e.target.value);
                    if (e.target.value.length > 14)
                      return;
                    formik.handleChange(e);
                  }}
                  value={formik.values.cpf}
                  inputProps={
                    { readOnly: !isCreating && readOnlyFields, }
                  }
                />
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                {isLoadingContatoByCpf ? <Skeleton variant="text" width={'100%'} height={60} /> :
                  <TextField
                    error={!!(formik.touched.nome && formik.errors.nome)}
                    fullWidth
                    helperText={String((formik.touched.nome && formik.errors.nome) ?? "")}
                    label="Nome"
                    name="nome"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.nome}
                    disabled={isCreating && formik.values.cpf.length < 14}
                    inputProps={
                      { readOnly: contactAlreadyExist || (!isCreating && readOnlyFields), }
                    }
                  />
                }
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                {isLoadingContatoByCpf ? <Skeleton variant="text" width={'100%'} height={60} /> :
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={String((formik.touched.email && formik.errors.email) ?? "")}
                    label="E-mail"
                    name="email"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    disabled={isCreating && formik.values.cpf.length < 14}
                    inputProps={
                      { readOnly: contactAlreadyExist || (!isCreating && readOnlyFields), }
                    }
                  />
                }
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                {isLoadingContatoByCpf ? <Skeleton variant="text" width={'100%'} height={60} /> :
                  <TextField
                    error={!!(formik.touched.celular && formik.errors.celular)}
                    fullWidth
                    helperText={String((formik.touched.celular && formik.errors.celular) ?? "")}
                    label="Telefone Celular"
                    name="celular"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.celular}
                    disabled={isCreating && formik.values.cpf.length < 14}
                    inputProps={
                      { readOnly: contactAlreadyExist || (!isCreating && readOnlyFields), }
                    }
                  />
                }
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 4,
                  mt: 1
                }}
              >
                <Typography variant="h6">
                  Informações da Visita
                </Typography>
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.seb_motivo && formik.errors.seb_motivo)}
                  fullWidth
                  helperText={String((formik.touched.seb_motivo && formik.errors.seb_motivo) ?? "")}
                  label="Motivo da visita"
                  name="seb_motivo"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value === 'Lead - Conhecer a Escola')
                      setIsEsporadico(true);
                    formik.handleChange(e)
                  }}
                  select={selectedVisita && formik.values.seb_motivo ? false : true}
                  value={formik.values.seb_motivo}
                >
                  {selectedVisita && formik.values.seb_motivo ? formik.values.seb_motivo : Object.keys(motivosVisitas).map((key) => {
                    if (!hasIdInContato && key === 'Lead - Conhecer a Escola') return null;
                    return <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  })}
                </TextField>
              </Stack>
              {formik.values.seb_motivo === 'Outro Motivo' &&
                <Stack
                  direction={{ md: 'row', sm: 'column' }}
                  spacing={3}
                  sx={{
                    mb: 3
                  }}
                >
                  <TextField
                    error={!!(formik.touched.seb_outro_motivo && formik.errors.seb_outro_motivo)}
                    fullWidth
                    helperText={String((formik.touched.seb_outro_motivo && formik.errors.seb_outro_motivo) ?? "")}
                    label="Descreva o motivo aqui"
                    name="seb_outro_motivo"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.seb_outro_motivo}
                  />
                </Stack>
              }
              {isCreating && formik.values.seb_motivo !== 'Lead - Conhecer a Escola' &&
                <Stack
                  direction={'row'}
                  spacing={3}
                  sx={{
                    mb: 3
                  }}
                >
                  <FormGroup
                    sx={{
                      flexDirection: "row"
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isEsporadico}
                          onChange={() => setIsEsporadico(true)}
                          name="isEsporadico"
                          color="primary"
                        />
                      }
                      label="Visita Esporádica"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!isEsporadico}
                          name="isEsporadico"
                          onChange={() => setIsEsporadico(false)}
                          color="primary"
                        />
                      }
                      label="Visita Recorrente"
                    />
                  </FormGroup>
                </Stack>
              }
              {isEsporadico ?
                <>
                  <Stack
                    direction={{ md: 'row', sm: 'column' }}
                    spacing={3}
                    sx={{
                      mb: 2
                    }}
                  >
                    <TextField
                      error={!!(formik.touched.seb_data_inicio && formik.errors.seb_data_inicio)}
                      fullWidth
                      helperText={String((formik.touched.seb_data_inicio && formik.errors.seb_data_inicio) ?? "")}
                      label="Dia da Visita"
                      name="seb_data_inicio"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={handleDateChange}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={formatDate(formik.values.seb_data_inicio)}
                    />
                  </Stack>
                  <Stack
                    direction={{ md: 'row', sm: 'column' }}
                    spacing={3}
                    sx={{
                      '& .MuiStack-root': {
                        width: "100%",
                        mb: 2
                      },
                      '& .MuiFormControl-root': {
                        minWidth: '0 !important'
                      }
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['TimePicker']}>
                        <TimePicker
                          label="Hora de Entrada"
                          name="startTime"
                          value={formik.values.startTime}
                          onChange={(value) => handleTimeChange('startTime', value)}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              error: !!(formik.touched.startTime && formik.errors.startTime),
                              helperText: String((formik.touched.startTime && formik.errors.startTime) ?? ""),
                              fullWidth: true
                            },
                          }}
                          viewRenderers={{
                            hours: (props) => renderTimeViewClock({ ...props, ampm: false }),
                            minutes: (props) => renderTimeViewClock({ ...props, ampm: false }),
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['TimePicker']}>
                        <TimePicker
                          label="Hora de Saída"
                          name="endTime"
                          value={formik.values.endTime}
                          onChange={(value) => handleTimeChange('endTime', value)}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              error: !!(formik.touched.endTime && formik.errors.endTime),
                              helperText: String((formik.touched.endTime && formik.errors.endTime) ?? ""),
                              fullWidth: true
                            }
                          }}
                          viewRenderers={{
                            hours: (props) => renderTimeViewClock({ ...props, ampm: false }),
                            minutes: (props) => renderTimeViewClock({ ...props, ampm: false }),
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                  <Stack
                    direction={{ md: 'row', sm: 'column' }}
                    spacing={3}
                    sx={{
                      mb: 3
                    }}
                  >
                  </Stack>
                </>
                :
                <Stack
                  direction={{ md: 'row', sm: 'column' }}
                  spacing={3}
                  sx={{
                    mb: 3
                  }}
                >
                  <TextField
                    error={!!(formik.touched.seb_data_inicio && formik.errors.seb_data_inicio)}
                    fullWidth
                    helperText={String((formik.touched.seb_data_inicio && formik.errors.seb_data_inicio) ?? "")}
                    label="Data Inicial"
                    name="seb_data_inicio"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formatDate(formik.values.seb_data_inicio)}
                  />
                  <TextField
                    error={!!(formik.touched.seb_data_fim && formik.errors.seb_data_fim)}
                    fullWidth
                    helperText={String((formik.touched.seb_data_fim && formik.errors.seb_data_fim) ?? "")}
                    label="Data Final"
                    name="seb_data_fim"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={handleDateChange}
                    sx={{
                      mt: isSmall ? 3 : 0
                    }}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formatDate(formik.values.seb_data_fim)}
                  />
                </Stack>
              }
            </Grid>
          </Grow>
          {!isEsporadico &&
            <Grow
              in={!loadingContato}
              easing="ease-out"
              mountOnEnter
              unmountOnExit
              timeout={800}
            >
              <Grid
                item
                xs={12}
              >
                <Stack
                  direction={{ md: 'row', sm: 'column' }}
                >
                  <Typography variant="h6">
                    Descreva os dias e horários que serão liberados
                  </Typography>
                </Stack>
                <Stack
                  direction={{ md: 'row', sm: 'column' }}
                  spacing={3}
                  sx={{
                    mb: 3
                  }}
                >
                  <TextField
                    fullWidth
                    label="De segunda a sexta das 06:00 as 18:00"
                    name="iaGenerativeText"
                    size="small"
                    multiline
                    rows={4}
                    onChange={(e) => setIaGenerativeText(e.target.value)}
                    value={iaGenerativeText}
                  />
                </Stack>
              </Grid>
            </Grow>
          }
          {(!isEsporadico && ((iaGenerativeText && iaGenerativeText !== "") || (entryTime.length > 0 || exitTime.length > 0))) && (
            <Grid
              item
              xs={12}
            >
              <HorarioComponent
                entryTime={entryTime}
                exitTime={exitTime}
                loadingHorarios={loadingHorarios}
                handleSave={handleSave}
              />
            </Grid>
          )}
          <Grid
            container
            width="100%"
            justifyContent="flex-end"
          >
            <Grid
              item
              md={3}
              sx={{
                mt: 1
              }}
              width="100%"
              justifyContent="center"
            >
              <Grow
                in={!loadingContato}
                easing="ease-out"
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  startIcon={saving ? <CircularProgress color="primary" size={20} /> : <SaveIcon />}
                  disabled={!canEditFoto || saving || (!formik.dirty && !entryTime && !exitTime)}
                >
                  {hasIdInContato ? "Salvar" : "Criar"}
                </Button>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Stack>
  ];


  const tableConfigs = {
    selection: false,
    editing: false,
    isEndedList: true,
    actions: [
      { id: "select-visit", label: <><Edit />Gerenciar</>, }
    ],
    onClickAction: (action, row) => {
      switch (action.id) {
        case "select-visit":
          handleSelectVisita(row);
          break;
        default:
          console.warn("Unhandled option: ", action);
          break;
      }
    },
  }
  const { currentStep, currentComponent, changeStep } = useFormSteps(visitanteComponents);
  if (errorText)
    return <ErrorMessage
      title="Erro ao carregar os dados"
      message={errorText}
    />


  return (
    <Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <div>
            <Typography
              variant="h4"
              sx={{
                mb: 3
              }}
            >
              Reconhecimento Facial
            </Typography>
          </div>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider />
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              {...{ timeout: 1000 }}
            >
              <Stack spacing={3}>
                <Stack
                  spacing={4}
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  sx={{
                    minHeight: 200,
                    backgroundColor: 'var(--bs-gray-200)',
                    borderRadius: 1,
                  }}
                >
                  {saving && etapas && etapas.length && (
                    <ProgressoRequisicoesComAnimacao
                      etapas={etapas}
                      successAnimation={successAnimation}
                      failureAnimation={errorAnimation}
                      onError={() => {
                        setSaving(false)
                        handleShowCsat(csatList.alteracaoCatracaVisitaContato)
                      }}
                      onSuccess={() => {
                        setSaving(false);
                        ExibeMensagem('Visita criada com sucesso', 'success', true);
                        changeStep(0);
                        getVisitas();
                        if (handleShowCsat) handleShowCsat(csatList.alteracaoCatracaVisitaContato);
                      }}
                    />
                  )}
                  <Stack
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      mt: isSmall ? 6 : 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      sx={{ px: 2 }}
                    >
                      Gerenciamento dos Visitantes na Unidade
                    </Typography>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ px: 2 }}
                    >
                      {currentStep === 0 ?
                        'Aqui você pode visualizar as visitas agendadas na unidade.' :
                        isCreating ? 'Crie a visita desejada' : 'Gerencie o visitante selecionado.'
                      }
                    </Typography>
                  </Stack>
                  {isSmall ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        gap: { xs: 5, md: 'none' },
                      }}
                    >
                      <Stepper
                        id="desktop-stepper"
                        activeStep={currentStep}
                        orientation="vertical"
                        connector={<QontoConnector />}
                        sx={{
                          width: '100%',
                          pb: 2
                        }}
                      >
                        {steps.map((label) => (
                          <Step
                            sx={{
                              ':first-child': { pl: 0 },
                              ':last-child': { pr: 0 },
                            }}
                            key={label}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: '80%',
                        gap: { xs: 5, md: 'none' },
                      }}
                    >
                      <Stepper
                        id="desktop-stepper"
                        activeStep={currentStep}
                        connector={<QontoConnector />}
                        sx={{
                          width: '100%',
                          height: 40,
                        }}
                      >
                        {steps.map((label) => (
                          <Step
                            sx={{
                              ':first-child': { pl: 0 },
                              ':last-child': { pr: 0 },
                            }}
                            key={label}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  )}
                  <Button
                    disabled={currentStep === 0}
                    color="primary"
                    type="button"
                    sx={{
                      position: 'absolute',
                      top: 10,
                      left: 10,
                      width: 'auto'
                    }}
                    onClick={(e) => {
                      if (currentStep === 1) {
                        setIsCreating(false)
                        getVisitas();
                      }
                      changeStep(currentStep - 1, e)
                    }}
                    startIcon={<KeyboardBackspace />}
                  >
                    {currentStep === 0 ? '' : 'Anterior'}
                  </Button>
                </Stack>
              </Stack>
            </Grow>
            {currentComponent &&
              <Grow
                in
                style={{ transformOrigin: '0 0 0' }}
                {...{ timeout: 1000 }}
              >
                <Box
                  sx={{
                    mt: 3,
                    minHeight: 200
                  }}
                >
                  {currentComponent}
                </Box>
              </Grow>
            }
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default withUnidade(
  ReconhecimentoFacial,
  "Reconhecimento Facial",
  csatList.alteracaoCatracaVisitaContato
);