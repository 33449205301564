import { useEffect, useState, Fragment } from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import withAppContext from "../../hocs/withAppContext";
import moment from "moment-timezone";
import QRCode from 'qrcode';
import * as _matriculaService from "../../service/matricula-service";
import * as _contatosService from "../../service/contatos-service";
import * as _carteirinhaTemplate from "../../service/carterinha-service";
import { Matricula } from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import { Unidade } from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import {Theme} from "@mui/material/styles/createTheme";

import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation';
import { Grid, TextField, Typography, Box, Button, Stack, Grow, MenuItem, Select, Skeleton, useMediaQuery } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import './styles.css';
import { Redirect } from 'react-router-dom';
import { Contato, TemplateCarteirinhaResponseByUnidade } from 'seb-graph-api-types/generated';

interface Props {
  Matricula: Matricula,
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

const CarteirinhaEscolar = (props: Props) => {
  const { Matricula, Unidade } = props;
  const [nomeServico, setNomeServico] = useState<string>(null);
  const [email, setEmail] = useState<string>(null);
  const [cpf, setCpf] = useState<string>(null);
  const [rg, setRg] = useState<string>(null);
  const [redirectToCadastro, setredirectToCadastro] = useState<boolean>(false);
  const [htmlContent, sethtmlContent] = useState<string>(null);
  const [selectedContato, setSelectedContato] = useState<Contato>(null);
  const [selectedMatricula, setSelectedMatricula] = useState<any>(null);
  const [unidadeTemplates, setUnidadeTemplates] = useState<TemplateCarteirinhaResponseByUnidade[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [htmlVerso, setHtmlVerso] = useState<string>(null);
  const [erroRequestTemplate, setErroRequestTemplate] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>(null);
  const [isloading, setIsLoading] = useState<boolean>(true);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState<boolean>(false);
  const [erroRequisicao, setErroRequisicao] = useState<boolean>(false);
  const [erroRequisicaoImagem, setErroRequisicaoImagem] = useState<boolean>(false);
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  moment.tz.setDefault("America/Sao_Paulo");

  useEffect(() => {
    setErroRequisicao(false);
    setErroRequisicaoImagem(false);
    setErroRequestTemplate(false);
    
    Promise.all([getHtmlTemplate()]);
  }, [Matricula])

  const replaceValues = (html, values) => {
    const regex = /{{(.*?)}}/g;
    const matches = html.match(regex);

    if (matches) {
      matches.forEach(match => {
        const key = match.slice(2, -2).trim();
        if (values.hasOwnProperty(key)) {
          html = html.replace(match, values[key]);
        }
      });
    }
    return html;
  }
  const filterName = async (nomeCompleto: string) => {
    const partesNome = nomeCompleto.split(' ');
    const primeiroNome = partesNome.shift();
    const nomesMeioAbreviados = partesNome.slice(0, -1).map(nome => `${nome.charAt(0)}.`);
    const ultimoSobrenome = partesNome.pop();
    const nomeAbreviado = [primeiroNome, ...nomesMeioAbreviados, ultimoSobrenome].join(' ');

    return nomeAbreviado;
  };

  const getHtmlByURL = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Erro ao buscar o template na URL: ${url}`);
      }
      return {
        htmlText: await response.text(),
        url: url,
      };
    } catch (error) {
      throw new Error(`Erro ao buscar o template da URL: ${url}.`);
    }
  };

  const onChangeTemplate = async (event) => {
    setErrorText(null);
    setErroRequestTemplate(false);
    setIsLoadingTemplate(true);
    try {
      const template = unidadeTemplates.find(template => template.nome === event.target.value);
      setSelectedTemplate(template.nome);
      const templateFrente = await getHtmlByURL(template.urlFrente);
      const templateVerso = template?.urlVerso ? await getHtmlByURL(template.urlVerso) : null;

      let qrCodeInfo = {
        school: Unidade.nome,
        enrollment: Matricula.codigoMatricula,
        name: Matricula.estudante_Name,
        document: selectedContato ? selectedContato?.rg ?? selectedContato?.cpf : null,
        issued: moment().valueOf().toString(),
        expires: moment(selectedMatricula.dataTermino).add(2, 'months').valueOf().toString(),
      };

      const urlRequest = await _matriculaService.generateQrCodeAuthorization(qrCodeInfo);
      const dataFormatada = moment(selectedContato?.dataNascimento).format("DD/MM/YYYY");

      let docType = null;
      let doc = null;
      if (selectedContato && selectedContato?.rg) {
        docType = 'RG';
        doc = selectedContato?.rg;
      } else if (selectedContato && selectedContato?.cpf) {
        docType = 'CPF';
        doc = selectedContato?.cpf;
      }

      let studentInfo = replaceValues(templateFrente.htmlText, {
        SCHOOLNAME: Unidade.nome,
        YEAR: selectedMatricula.anoLetivo_Name,
        SERVICE: selectedMatricula.curso_Name,
        ENROLLMENT: Matricula.codigoMatricula,
        BIRTHDATE: dataFormatada ?? '',
        EDUCATIONLEVEL: selectedMatricula.segmento_Name,
        STUDENTNAME: await filterName(Matricula.estudante_Name),
        RA: Matricula.codigoMatricula,
        MAINDOCTYPE: docType ?? '',
        MAINDOC: doc ?? null,
        EXPIRATION: moment(selectedMatricula.dataTermino).add(2, 'months').format("MM/YYYY"),
        PHOTOURL: selectedContato?.fotoPrincipal?.publicURL,
        QRCODE: await QRCode.toDataURL(urlRequest.url),
      });

      const studentInfoVerso = replaceValues(templateVerso && templateVerso.htmlText && templateVerso.htmlText, {
        SCHOOLNAME: Unidade.nome,
        YEAR: selectedMatricula.anoLetivo_Name,
        SERVICE: selectedMatricula.curso_Name,
        ENROLLMENT: Matricula.codigoMatricula,
        BIRTHDATE: dataFormatada ?? '',
        EDUCATIONLEVEL: selectedMatricula.segmento_Name,
        STUDENTNAME: await filterName(Matricula.estudante_Name),
        RA: Matricula.codigoMatricula,
        MAINDOCTYPE: docType ?? '',
        MAINDOC: doc ?? null,
        EXPIRATION: moment(selectedMatricula.dataTermino).add(2, 'months').format("MM/YYYY"),
        PHOTOURL: selectedContato?.fotoPrincipal?.publicURL,
        QRCODE: await QRCode.toDataURL(urlRequest.url),
      });

      sethtmlContent(studentInfo);
      if (templateVerso && templateVerso.htmlText) setHtmlVerso(studentInfoVerso ?? templateVerso.htmlText);
    } catch (error) {
      setErroRequestTemplate(true);
      setErrorText("Erro ao buscar o template da carteirinha!");
    } finally {
      setIsLoadingTemplate(false);
    }
  };

  

  const getHtmlTemplate = async (prevTemplate = null) => {
    try {
      setIsLoading(true);

      const contato = await _contatosService.GetByContatoMatriculaCRM(Matricula.idMatricula);
      setSelectedContato(contato[0]);
      const unidadeTemplates = await _carteirinhaTemplate.getCarteirinhaTemplateByUnidade(Unidade.id);
      setUnidadeTemplates(unidadeTemplates);

      let validURL = prevTemplate ? prevTemplate : unidadeTemplates.find(template => template.urlFrente && template.urlVerso);
      if (!validURL) validURL = unidadeTemplates.find(template => template.urlFrente);

      if (!validURL) {
        throw new Error("Não foi possível encontrar o template da carteirinha");
      }
      setSelectedTemplate(validURL.nome);

      const template = await getHtmlByURL(validURL.urlFrente);
      const templateVerso = validURL?.urlVerso ? await getHtmlByURL(validURL.urlVerso) : null;

      const matricula = await _matriculaService.GetMatricula(Matricula.idMatricula);
      setSelectedMatricula(matricula);
      setErroRequisicaoImagem(false);
      if (!template) {
        throw new Error("Erro ao buscar o template da carteirinha");
      } else if (!contato) {
        throw new Error("Erro ao buscar o contato do aluno no CRM");
      } else if (!contato[0]?.fotoPrincipal?.publicURL) {
        setErroRequisicaoImagem(true);
        setErrorText('Sem foto Cadastrada!');
        setIsLoading(false);
        return;
      }

      const qrCodeInfo = {
        school: Unidade.nome,
        enrollment: Matricula.codigoMatricula,
        name: Matricula.estudante_Name,
        document: contato ? contato[0]?.rg ?? contato[0]?.cpf : null,
        issued: moment().valueOf().toString(),
        expires: moment(matricula.dataTermino).add(2, 'months').valueOf().toString(),
      };

      if (!nomeServico) setNomeServico(Matricula.servico.split('-')[1]);
      if (contato && contato[0]?.rg) setRg(contato[0]?.rg);
      if (contato && contato[0]?.cpf) setCpf(contato[0]?.cpf);
      if (contato && contato[0]?.email) setEmail(contato[0]?.email);

      let docType = null;
      let doc = null;
      if (contato && contato[0]?.rg) {
        docType = 'RG';
        doc = contato[0]?.rg;
      } else if (contato && contato[0]?.cpf) {
        docType = 'CPF';
        doc = contato[0]?.cpf;
      }

      const urlRequest = await _matriculaService.generateQrCodeAuthorization(qrCodeInfo);
      const dataFormatada = moment(contato[0]?.dataNascimento).format("DD/MM/YYYY");

      const studentInfo = replaceValues(template.htmlText, {
        SCHOOLNAME: Unidade.nome,
        YEAR: matricula.anoLetivo_Name,
        SERVICE: matricula.curso_Name,
        ENROLLMENT: Matricula.codigoMatricula,
        BIRTHDATE: dataFormatada ?? '',
        EDUCATIONLEVEL: matricula.segmento_Name,
        STUDENTNAME: await filterName(Matricula.estudante_Name),
        RA: Matricula.codigoMatricula,
        MAINDOCTYPE: docType ?? '',
        MAINDOC: doc ?? null,
        EXPIRATION: moment(matricula.dataTermino).add(2, 'months').format("MM/YYYY"),
        PHOTOURL: contato[0]?.fotoPrincipal?.publicURL,
        QRCODE: await QRCode.toDataURL(urlRequest.url),
      });

      const studentInfoVerso = templateVerso && templateVerso.htmlText && replaceValues(templateVerso.htmlText, {
        SCHOOLNAME: Unidade.nome,
        YEAR: matricula.anoLetivo_Name,
        SERVICE: matricula.curso_Name,
        ENROLLMENT: Matricula.codigoMatricula,
        BIRTHDATE: dataFormatada ?? '',
        EDUCATIONLEVEL: matricula.segmento_Name,
        STUDENTNAME: await filterName(Matricula.estudante_Name),
        RA: Matricula.codigoMatricula,
        MAINDOCTYPE: docType ?? '',
        MAINDOC: doc ?? null,
        EXPIRATION: moment(matricula.dataTermino).add(2, 'months').format("MM/YYYY"),
        PHOTOURL: contato[0]?.fotoPrincipal?.publicURL,
        QRCODE: await QRCode.toDataURL(urlRequest.url),
      });

      sethtmlContent(studentInfo);
      if (templateVerso && templateVerso.htmlText) setHtmlVerso(studentInfoVerso ?? templateVerso.htmlText);

      setIsLoading(false);

    } catch (error) {
      setErroRequisicao(true);
      setErrorText(error.message);
      setIsLoading(false);
    }
  };

  const handleRedirect = () => {
    setredirectToCadastro(true);
  }

  const printPDF = () => {
    window.print();
  };


  if (erroRequisicaoImagem) {
    return (
      <Fragment>
        <ErrorMessage message={errorText} />
        {redirectToCadastro && <Redirect to="/reconhecimento-facial/alunos" />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            component="label"
            variant="contained"
            color='primary'
            onClick={handleRedirect}
            endIcon={<HowToRegIcon />}
          >
            Cadastrar Imagem
          </Button>
        </Box>
      </Fragment>
    )
  }
  if (erroRequisicao) {
    return <ErrorMessage message={errorText} />
  }

  if (isloading) {
    return (<LoadingAnimation> </LoadingAnimation>)
  }
  return (
    <Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack sx={{ mb: 3 }}>
          <Box
            sx={md ? { 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'space-between' 
            } : { 
              display: 'flex', 
              justifyContent: 'space-between'
            }}
          >
            <Stack>
              <Typography
                variant="h4"
              >
                Carteirinha Digital
              </Typography>
            </Stack>
            <Stack 
              spacing={2} 
              direction="row"
              sx={md && {
                mt: 2,
              }}
            >
              <Select
                name="selectedTemplate"
                variant='outlined'
                value={selectedTemplate}
                onChange={(event) => onChangeTemplate(event)}
                displayEmpty
                fullWidth={md ? true : false}
                sx={{
                  minWidth: 200,
                  mt: 2,
                  '& legend': {
                    display: 'none'
                  }
                }}
              >
                <MenuItem value="" disabled>
                  Selecione um template
                </MenuItem>
                {unidadeTemplates.map(template => (
                  <MenuItem key={template.nome} value={template.nome}>
                    {template.nome}
                  </MenuItem>
                ))}
              </Select>
              <Button
                component="label"
                variant="contained"
                color='primary'
                fullWidth={md ? true : false}
                onClick={printPDF}
                startIcon={<PrintIcon />}
              >
                Imprimir
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Grow
          in={!isloading}
          easing="ease-out"
          mountOnEnter
          unmountOnExit
          timeout={800}
        >
          <Grid
            container
            spacing={2}
          >
            {erroRequestTemplate ? 
            <Grid
              item
              xs={12}
            >
              <ErrorMessage message={errorText} />
            </Grid> : 
            <Grid
              item
              xs={12}
              lg={8}
              xl={6}
            >
              {isLoadingTemplate ?
                <>
                  <Skeleton variant="rectangular" height={400} />
                  <Box sx={{ mt: 2 }} />
                  <Skeleton variant="rectangular" height={400} />
                </> :
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    p: 3,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <div id='carteirinha-container' className="carteirinha-container" dangerouslySetInnerHTML={{ __html: htmlContent }}>
                  </div>
                  {htmlVerso &&
                    <div id='carteirinha-verso' className="carteirinha-verso" dangerouslySetInnerHTML={{ __html: htmlVerso }}>
                    </div>
                  }
                </Box>
              }
            </Grid>
            }
            {!erroRequestTemplate ? isLoadingTemplate ?
              <Grid
                item
                xs={12}
                lg={4}
                xl={6}
              >
                <Skeleton variant="rectangular" height={400} />
              </Grid>
              :
              <Grid
                item
                xs={12}
                lg={4}
                xl={6}
              >
                <Stack
                  spacing={3}
                  sx={{ mb: 3 }}
                >
                  <TextField
                    id="outlined-basic"
                    variant='standard'
                    label="Nome do Serviço"
                    disabled
                    value={nomeServico}
                    onChange={(event) => setNomeServico(event.target.value)}
                    fullWidth
                  />
                </Stack>
                <Stack
                  spacing={3}
                  sx={{ mb: 3 }}
                >
                  <TextField
                    id="outlined-basic"
                    variant='standard'
                    label="Email"
                    disabled
                    value={email}
                    fullWidth
                  />
                </Stack>
                <Stack
                  spacing={3}
                  sx={{ mb: 3 }}
                >
                  <TextField
                    id="outlined-basic"
                    variant='standard'
                    label="RG"
                    disabled
                    value={rg}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    variant='standard'
                    label="CPF"
                    disabled
                    value={cpf}
                    fullWidth
                  />
                </Stack>
                <Stack
                  spacing={3}
                  sx={{ mb: 3 }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: 'text.secondary',
                      fontStyle: 'italic',
                      textAlign: 'flex-start'
                    }}
                  >
                    *Dados provenientes do CRM.
                  </Typography>
                </Stack>

              </Grid>
            : null}
          </Grid>
        </Grow>
      </Box>
    </Fragment>
  );
}

export default withAppContext(withUnidadeMatricula(CarteirinhaEscolar, 'Carteirinha Escolar'));