import {gql} from "@apollo/client";
import deepCopy from "../utils/deepCopy";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function ListMatriculas(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return await client.query({
        query: gql`
            query GetMatriculas($filter: MatriculaFilterInput!) {
                matriculas(filter: $filter) {
                    anoLetivo_Name
                    codigoCliente
                    codigoMatricula
                    codigoMatriculaErp
                    estudante_Name
                    idAluno
                    idMatricula
                    periodo
                    servico
                    status
                    turma_Name
                    tipoItem
                }
            }
        `,
        variables: {
            filter: request
        }
    }).then(resp => {
        const {
            data: {
                matriculas
            },
            errors = []
        } = resp;

        dispatchGraphError(errors);

        return [...matriculas] || [];
    })
    .then(matriculas => deepCopy(matriculas))
    .catch(console.error);
}
export async function ListMatriculasByPage(request, pagRequest) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return await client.query({
        query: gql`
            query MatriculasByPage($filter: MatriculaFilterInput!, $pagination: Pagination) {
                matriculasByPage(filter: $filter, pagination: $pagination) {
                    count
                    rows{
                        anoLetivo_Name
                        codigoCliente
                        codigoMatricula
                        codigoMatriculaErp
                        estudante_Name
                        idAluno
                        idMatricula
                        periodo
                        servico
                        status
                        turma_Name
                        segmento
                        tipoMatricula
                        nomeUnidade
                        dataCriacao
                        dataEfetivacao
                    }
                }
            }
        `,
        variables: {
            filter: request,
            pagination: pagRequest
        }
    }).then(resp => {
        const {
            data: {
                matriculasByPage
            },
            errors = []
        } = resp;

        dispatchGraphError(errors);

        return matriculasByPage || [];
    })
    .then(matriculasByPage => deepCopy(matriculasByPage))
    .catch(console.error);
}
export async function GetMatricula(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getByIdMatricula
        },
        error,
        errors
    } = await client.query({
        query: gql`
        query GetMatricula($id: ID!) {
        getByIdMatricula(id: $id) {
            codigoCliente
            codigoMatricula
            codigoMatriculaErp
            idAluno
            idMatricula
            periodo
            servico
            status
            id
            unidade_Name
            unidade_Id
            servico_Name
            servico_Id
            anoLetivo_Name
            anoLetivo_Id
            estudante_Name
            estudante_Id
            responsavelContratual_Name
            responsavelContratual_Id
            turma_Name
            turma_Id
            curso_Name
            curso_Id
            segmento_Name
            segmento_Id
            periodo_Value
            status_Value
            razaoStatus_Value
            razaoStatus_Nome
            tipoMatricula_Value
            dataDaMatricula
            urlContrato
            nomeLandingPage
            evolucaoMatricula
            dataEvolucaoMatricula
            cpfResponsavelAceite
            iPdeOrigemAceite
            dataAceite
            valorReserva
            descontoBolsaEstudo
            descontoBolsaIrmaos
            descontoBolsaNova
            descricaoDoCancelamento
            tipoMatricula {
                valor,
                  descricao
            }
            itensContrato
            responsavelFinanceiro
            dataUltimoPagamento
            valorUltimoPagamento
            adimplente
            dadosAluno {
                id
                nome
                email
                rg
                cpf
                dataNascimento
                urlImagemPerfil
                codigoErp
                codigoCliente
                razaoStatusValue
                statusValue
                razaoStatusNome
                statusNome
                cadastroPendente
                criadoEm
            }
            descricaoBoleto
            codigobarrasBoleto
            urlBoleto
            documentosValidados
            disponivelParaMatricular
            bloqueioColaborador
            bloqueioPedagogico
            bloqueioSistemico
            matriculaBloqueada
            msgMatriculaBloqueada
            dataDeCancelamento
            motivoDoCancelamento
            entradaPaga
            idMatriculaPai
            bloqueioContratacaoUnidade
            mensagemBloqueioUnidade
            dataInicioVigencia
            dataTermino
        }
        }`,
        variables: {
            id: idMatricula
        }
    })
    if (error) {
        alert("Erro ao listar matriculas.");
    }

    dispatchGraphError(errors);

    return getByIdMatricula;
}


export async function ListMotivosCancelamento() {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getCancelamentoMotivos
        },
        errors
    } = await client.query({
        query: gql`
        query GetCancelamentoMotivos {
          getCancelamentoMotivos {
            id
            codigo
            descricao
          }
        }
        `
    })

    dispatchGraphError(errors);

    return getCancelamentoMotivos;
}
export async function IncluirNovoMotivoCancelamento({...request}) {
    delete request.__typename;
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            criaMotivoCancelamentoMatricula
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation CriaMotivoCancelamentoMatricula($motivo: MotivoCancelamentoRequest) {
                criaMotivoCancelamentoMatricula(motivo: $motivo) {
                    codigo
                    descricao
                }
            }
        `,
        variables: {
            motivo: request
        }
    })

    dispatchGraphError(errors);

    return criaMotivoCancelamentoMatricula;
}

export async function DeletarMotivoCancelamento({...request}) {
    delete request.__typename;
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            deletarMotivoCancelamentoMatricula
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation DeletarMotivoCancelamentoMatricula($motivo: MotivoCancelamentoRequest) {
                deletarMotivoCancelamentoMatricula(motivo: $motivo) {
                    codigo
                    descricao
                }
            }
        `,
        variables: {
            motivo: request
        }
    })

    dispatchGraphError(errors);

    return deletarMotivoCancelamentoMatricula;
}



export async function AtualizarMotivoCancelamento({...request}) {
    delete request.__typename;
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            alteraMotivoCancelamentoMatricula
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation AtualizaMotivoCancelamentoMatricula($motivo: MotivoCancelamentoRequest) {
                alteraMotivoCancelamentoMatricula(motivo: $motivo) {
                    codigo
                    descricao
                }
            }
        `,
        variables: {
            motivo: request
        }
    })

    dispatchGraphError(errors);

    return alteraMotivoCancelamentoMatricula;
}

export function TrocarCursoNovaMatricula(idMatricula, idServicoUnidade, idTurma) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation:  gql`
            mutation TrocarCursoNovaMatricula($idMatricula: String, $idServicoUnidade: String, $idTurma: String) {
                trocarCursoNovaMatricula(idMatricula: $idMatricula, idServicoUnidade: $idServicoUnidade, idTurma: $idTurma) {
                    value {
                        id
                    }
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            idMatricula,
            idServicoUnidade,
            idTurma
        }
    }).then(data => {
        const {
            data: {
                trocarCursoNovaMatricula
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return trocarCursoNovaMatricula;
    }).catch(console.error)
}
export async function GetMatriculaGeral(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getMatriculaGeral
        },
        errors
    } = await client.query({
        query: gql`
            query GetMatriculaGeral($idMatricula: String) {
                getMatriculaGeral(idMatricula: $idMatricula) {
                    aluno {
                        nome
                        codigoErp
                        dataNascimento
                        rg
                        cpf
                        telefoneFixo
                    }
                    matricula {
                        codigoMatricula
                        codigoMatriculaErp
                        razaoStatus_Nome
                        dataDaMatricula
                        dataDeCancelamento
                        motivoDoCancelamento
                    }
                    unidade {
                        codigoERP
                        nome
                    }
                    servicoUnidade {
                        codigo
                        nome
                        dataInicio
                        dataTermino
                    }
                    historicoDeBolsas {
                        tipoBolsaNome
                        percentual
                        vigenciaDe
                        vigenciaAte
                        razaoStatus
                    }
                    bolsaVigente {
                        tipoBolsaNome
                        percentual
                        vigenciaDe
                        vigenciaAte
                        razaoStatus
                    }
                    descricaoValorTotal
                    servicoMatriculaList {
                        itemIdName
                        eObrigatorio
                        configId
                        paymTermId
                        unitPrice
                        scholarshipValue
                        totalDiscount
                        discountMethod
                        netValue
                        responsibleName
                        planoDePagamento
                    }
                }
            }
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return getMatriculaGeral;
}

export async function GetBolsasByMatricula(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getBolsasByMatricula
        },
        errors
    } = await client.query({
        query: gql`
            query GetBolsasByMatricula($idMatricula: String) {
                getBolsasByMatricula(idMatricula: $idMatricula) {
                    id
                    tipoBolsaNome
                    percentual
                    vigenciaDe
                    vigenciaAte
                    razaoStatus
                    dataCriacao
                    descricao
                    nomeSolicitante
                }
            }
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return getBolsasByMatricula;
}



export function generateQrCodeAuthorization(qrCodeInfo) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.query({
        query : gql`
            mutation GenerateQrCodeAuthorization($qrCodeInfo: QrCodeInfo) {
                generateQrCodeAuthorization(qrCodeInfo: $qrCodeInfo) {
                    text,
                    signature,
                    url
                }
            }
        `,
        variables: {
            qrCodeInfo
        }
    }).then((res) => {
        const {
            data: {
                generateQrCodeAuthorization
            },
            errors
        } = res
        dispatchGraphError(errors);
        return generateQrCodeAuthorization;
    }).catch(console.error)
}