import type { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

interface Props {
    controller: any;
    isSelected?: boolean;
    handleSelect?: (id: any) => void;
}

const ControllerCard: FC<Props> = ({ controller = null, handleSelect = () => {}, isSelected = false }) => {
    return (
        <Card>
            <Stack
                onClick={() => handleSelect(controller.id)}
                position="relative"
                sx={{
                    backgroundColor: (theme) => theme.palette.mode === 'dark' && isSelected
                        ? 'primary.darkest'
                        : 'var(--bs-gray-200)',
                    p: 2,
                    maxWidth: 100,
                    display: 'flex',
                    height: 150,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.mode === 'dark' && isSelected
                            ? 'primary.darkest'
                            : 'var(--bs-gray-300)',
                        scale: '1.05',
                        cursor: 'pointer',
                        boxShadow: (theme) => theme.shadows[4],
                    }
                }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    flexDirection="row"
                    sx={{
                        height: '55%',
                        '& img': {
                            maxHeight: '100%',
                            objectFit: 'contain',
                        }
                    }}
                >
                    <img alt='Card-Icon' src='img/glass-icons/glass-scan-shield.png' />
                    <IconButton
                        type='button'
                        sx={{
                            color: 'primary.main',
                            position: 'absolute',
                            padding: 0.5,
                            top: 0,
                            right: 0,
                            width: 'auto',
                        }}
                    >
                        {isSelected ? <CheckCircleIcon color='primary' /> : <CheckCircleIcon color='disabled' />}
                    </IconButton>
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        height: '45%'
                    }}>
                    <Typography
                        color="text.primary"
                        display="-webkit-box"
                        sx={{
                            mt: 1,
                            fontWeight: 700,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical'
                        }}
                        variant={"h6"}
                    >
                        {controller.name.split('-')[1]}
                    </Typography>
                </Box>
            </Stack>
        </Card>
    );
}
export default ControllerCard;