import {ApolloClient, gql, NormalizedCacheObject} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";
//@ts-ignore
// import { QuestionBody  } from "seb-graph-api-types/generated";

interface QuestionBody {
    use_cache: boolean;
    json_response: boolean;
    message: string;
}

export async function generateAiInterval(questionBody: QuestionBody) : Promise<any> {
    // @ts-ignore
    const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
      const {
        data: {
          generateAiInterval : {
            response : generateAiIntervalData
          }
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation GenerateAiInterval($questionBody: QuestionBody) {
              generateAiInterval(questionBody: $questionBody) {
                response {
                  entry {
                    id
                    startTime
                    endTime
                    isMonday
                    isTuesday
                    isWednesday
                    isThursday
                    isFriday
                    isSaturday
                    isSunday
                  }
                  exit {
                    id
                    startTime
                    endTime
                    isMonday
                    isTuesday
                    isWednesday
                    isThursday
                    isFriday
                    isSaturday
                    isSunday
                  }
                }
              }
            }
        `,
      variables: {
        questionBody
      }
    });
  
    // @ts-ignore
    dispatchGraphError(errors);
  
    return generateAiIntervalData;
}