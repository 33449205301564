import {createComponent} from "@lit/react";
import React from "react";
import components from "seb-survey-components";
import Box from "@mui/material/Box";

const LitComponent = createComponent({
  tagName: 'seb-score-smile-face',
  //@ts-ignore
  elementClass: components.SebScoreSmileFace,
  react: React,
  events: {
  },
});


const SmileFace: React.FC<{
  start: number
  end: number
  score: number
  color?: string
}> = props => {

  const colorBasedOnScore = (score: number) => {
    const scoreInPercentage = (score - props.start) / (props.end - props.start);

    if (scoreInPercentage < 0.5) {
      return 'red';
    }

    if (scoreInPercentage < 0.8) {
      return '#ffbd00';
    }

    return 'green';
  }

  return (
    <Box
      sx={{
        borderRadius: '50%',
        backgroundColor: 'white',
        border: `2px solid ${colorBasedOnScore(props.score)}`,
        aspectRatio: '1/1',
        width: '24px',
        height: '24px',
        "--seb-primary-color": `${colorBasedOnScore(props.score)}`,
        "seb-score-smile-face": {
          transform: 'scale(0.2)',
          display: 'block',
          width: '50px',
          height: '50px',
          mt: '-18px',
          ml: '-15px',
        },
      }}
    >
      <LitComponent
        start={props.start}
        end={props.end}
        score={props.score}
      />
    </Box>
  );
}

export default SmileFace;
