import {ApolloClient, gql, NormalizedCacheObject} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";
import {TemplateCarteirinhaResponse, TemplateCarteirinhaResponseByUnidade} from "seb-graph-api-types/generated";

export const getAllTemplatesCarteirinha = async (): Promise<TemplateCarteirinhaResponse[]> => {
    // @ts-ignore
    const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const {
        data: {
            getAllTemplatesCarteirinha
        },
        errors
    } = await client.query({
        query: gql`
            query GetAllTemplatesCarteirinha {
                getAllTemplatesCarteirinha {
                    id
                    nome
                    urlFrente
                    urlVerso
                    guidUnidade
                    modificacao
                    criadoEm
                }
            }
        `
    })

    // @ts-ignore
    dispatchGraphError(errors);

    return getAllTemplatesCarteirinha;
}


export const getCarteirinhaTemplateByUnidade = async (unidadeId: string): Promise<TemplateCarteirinhaResponseByUnidade[]> => {
    // @ts-ignore
    const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const {
        data: {
            getCarteirinhaTemplateByUnidade
        },
        errors
    } = await client.query({
        query: gql`
            query GetCarteirinhaTemplateByUnidade($unidadeId: String) {
                getCarteirinhaTemplateByUnidade(unidadeId: $unidadeId) {
                    id
                    nome
                    urlFrente
                    urlVerso
                    modificacao
                    criadoEm
                    unidade {
                    id
                    nome
                    nomeLogico
                    }
                }
            }
        `,
        variables: {
            unidadeId
        }
    })

    // @ts-ignore
    dispatchGraphError(errors);

    return getCarteirinhaTemplateByUnidade;
}