import { useState } from 'react'


export function useFormSteps(steps : JSX.Element[]) {
    const [currentStep, setCurrentStep] = useState(0)

    const changeStep = (i : number, e : React.MouseEvent = null) => {
        if (e)
            e.preventDefault()
        if (i > steps.length)
            return
        
        setCurrentStep(i)
    }


    return {
        currentStep,
        currentComponent: steps[currentStep],
        changeStep
    }
}