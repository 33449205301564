import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Modal, Box, Typography, LinearProgress, Button } from '@mui/material';

export interface Etapa {
  requisicao: (resultadoAnterior?: any) => Promise<any>;
  titulo: string;
  animacao: any;
  contextText?: string;
}

interface ProgressoRequisicoesComAnimacaoProps {
  etapas: Etapa[];
  successAnimation: any;
  failureAnimation: any;
  onError?: (error: any) => void;
  onSuccess?: (resultado: any) => void;
}

export const ProgressoRequisicoesComAnimacao = (props: ProgressoRequisicoesComAnimacaoProps) => {
  const { etapas, successAnimation, failureAnimation, onError, onSuccess } = props;
  const [etapaAtual, setEtapaAtual] = useState(0);
  const [modalAberto, setModalAberto] = useState(true);
  const [resultadoAnterior, setResultadoAnterior] = useState<any>(null);
  const [sucesso, setSucesso] = useState<boolean | null>(null);
  const [erro, setErro] = useState<string | null>(null);
  const totalRequisicoes = etapas.length;
  const executarEtapa = async () => {
    try {
      const resultado = await etapas[etapaAtual].requisicao(resultadoAnterior);
      setResultadoAnterior(resultado);
      if (!etapas[etapaAtual + 1]) {
        setSucesso(true);
        if (onSuccess) onSuccess(resultado);
      } else
        setEtapaAtual(etapaAtual + 1);
    } catch (error: any) {
      setErro(`Ocorreu um erro na etapa ${etapas[etapaAtual].titulo}`);
      setSucesso(false);
      onError(error);
    }
  };

  useEffect(() => {
    if (etapas && etapaAtual < etapas.length) {
      executarEtapa();
    }
  }, [etapaAtual]);
  
  const fecharModal = () => {
    setModalAberto(false);
  };

  if (!etapas || !etapas.length) {
    return
  }

  return (
    <Modal open={modalAberto}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        textAlign: 'center',
      }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Progresso
        </Typography>
        {etapas[etapaAtual]?.contextText && 
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {etapas[etapaAtual].contextText}
          </Typography>
        }

        {!sucesso && etapas[etapaAtual] && etapas[etapaAtual].titulo && !erro && (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: etapas[etapaAtual].animacao,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={100}
              width={100}
            />
            <Box sx={{ width: '100%', marginTop: 2 }}>
              <LinearProgress
                variant="determinate"
                value={(etapaAtual / totalRequisicoes) * 100}
              />
              <Typography sx={{ marginTop: 1 }}>
                {etapaAtual} de {totalRequisicoes} passos concluídos
              </Typography>
            </Box>
          </>
        )}

        {sucesso && (
          <>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: successAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={100}
              width={100}
            />
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Processo finalizado com sucesso!
            </Typography>
            <Button onClick={fecharModal} sx={{ marginTop: 2 }} variant="contained" color="primary">
              Fechar
            </Button>
          </>
        )}

        {!sucesso && erro && (
          <>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: failureAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={100}
              width={100}
            />
            <Typography variant="h6" sx={{ marginTop: 2, color: 'red' }}>
              {erro}
            </Typography>
            <Button onClick={fecharModal} sx={{ marginTop: 2 }} variant="contained" color="error">
              Fechar
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProgressoRequisicoesComAnimacao;